export default {
    namespaced: true,
    state: {
        // /** 获取功能 */
        // get funcList() {
        //     const funcScope = localStorage.getItem('func-list');
        //     return !funcScope ? [] : JSON.parse(funcScope);
        // },
        // /** 设置功能 */
        // set funcList(value) {
        //     localStorage.setItem('func-list', JSON.stringify(value));
        // }
        funcList:[],
        roles:[]
    },
    getters: {},
    mutations: {},
    actions: {}
}
