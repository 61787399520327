// 表单重置
export function resetForm(refName) {
  if (this.$refs[refName]) {
    this.$refs[refName].resetFields();
  }
}

// 添加日期范围
export function addDateRange(params, dateRange, propName) {
  var search = params;
  if (typeof search.params === "undefined") {
    search.params = {};
  }

  let beginPropName = "beginTime";
  let endPropName = "endTime";
  if (typeof propName != "undefined") {
    beginPropName = "begin" + propName;
    endPropName = "end" + propName;
  }

  delete search.params[beginPropName];
  delete search.params[endPropName];

  if (null != dateRange && "" != dateRange) {
    search.params[beginPropName] = dateRange[0];
    search.params[endPropName] = dateRange[1];
  }
  return search;
}

// 回显数据字典
export function getDictLabel(datas, value) {
  let actions = [];
  Object.keys(datas).some((key) => {
    if (datas[key].value == "" + value) {
      actions.push(datas[key].label);
      return true;
    }
  });
  return actions.join("");
}

// 回显数据字典（字符串数组）
export function getDictLabels(datas, value, separator) {
  let actions = [];
  let currentSeparator = undefined === separator ? "," : separator;
  let temp = value.split(currentSeparator);
  Object.keys(value.split(currentSeparator)).some((val) => {
    Object.keys(datas).some((key) => {
      if (datas[key].value == "" + temp[val]) {
        actions.push(datas[key].label + currentSeparator);
      }
    });
  });
  return actions.join("").substring(0, actions.join("").length - 1);
}

// 提取请求头值
function extractHeaderValue(headers, key) {
  let value = headers[key];
  if (value) return value;
  value = headers[key.toLowerCase()];
  return value;
}

// 下载操作处理
export function downloadFile(rsp) {
  let headers = rsp.headers;
  let isError = extractHeaderValue(headers, "Is-Error");
  if (isError && isError == "1") {
    let error = decodeURI(extractHeaderValue(headers, "Error-Msg"));
    this.toast(error, "warning");
    return error;
  }
  let url = window.URL.createObjectURL(rsp.data);
  let link = document.createElement("a");
  link.style.display = "none";
  link.href = url;
  let fileName = decodeURI(extractHeaderValue(headers, "File-Name"));
  link.setAttribute("download", fileName);
  link.click();
  window.URL.revokeObjectURL(url);
}

// 属性是否禁用
export function propIsDisabled(data, prop) {
  if (data) {
    let forbid = data.forbid;
    if (forbid != undefined && forbid != null && forbid != "") {
      try {
        let props = JSON.parse(forbid);
        // console.log("props", props);
        if (Array.isArray(props)) {
          return props.indexOf(prop) != -1;
        }
      } catch (err) {
        // console.log("parse error", err);
        return false;
      }
    }
  }
  return false;
}
