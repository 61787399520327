import { ReqOrganizationGetSelectList } from "@/service/organization";
import { changeCurrentOrg } from "@/service/sys/user";

export default {
  namespaced: true,
  state: {
    // 当前组织主键
    org: {},
    // 全部组织列表
    orgs: [],
    // 是否显示全局
    show: false,
    // 协同结算角色
    cooRole: undefined,
  },
  getters: {},
  mutations: {
    // 设置当前组织
    SET_ORG: (state, orgId) => {
      state.org = state.orgs.find((item) => item.id == orgId);
    },
    // 缓存全部组织
    SET_ORGS: (state, orgs) => {
      state.orgs = orgs;
    },
    SET_SHOW: (state, show) => {
      state.show = show;
    },
    COO_ROLE: (state, role) => {
      state.cooRole = role;
    },
  },
  actions: {
    // 加载全部组织
    LoadOrgs({ commit }) {
      return new Promise((resolve, reject) => {
        ReqOrganizationGetSelectList()
          .then((res) => {
            if (res.success) {
              let orgList = [];
              orgList.push({ id: null, name: "全部", taxNo: "AA" });
              orgList.push({ id: 0, name: "个人", taxNo: "OO" });
              orgList.push(...res.data);
              commit("SET_ORGS", orgList);
            }
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    ChangeOrg({ commit }, orgId) {
      return new Promise((resolve, reject) => {
        changeCurrentOrg({ id: orgId })
          .then((res) => {
            if (res.success) {
              commit("SET_ORG", orgId);
            }
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    ChangeShow({ commit }, show) {
      return new Promise((resolve) => {
        commit("SET_SHOW", show);
        resolve();
      });
    },
    SetCooRole({ commit }, role) {
      return new Promise((resolve) => {
        commit("COO_ROLE", role);
        resolve();
      });
    },
  },
};
