import {get, post, request} from "./index.js";

/**
 * 个人中心用户信息
 * @param {*} data
 */
export const getPersonalCenterUserInfo = () => {
    return get("/tenant/getPersonCenterInfo", {}).then(res => res);
};
export const getUserLogo = () => {
    return get("/tenant/getPersonLogo", {}).then(res => res);
};

export const ReqUserGetFuncScope = () => {
    return get("/user/getFuncScope", {}).then(res => res);
};

export const ReqUserGetListByPage = (data) => {
    return post("/user/getListByPage", data).then(res => res);
};

export const uploadUser = (data) => {
    return post("/user/upload", data).then(res => res);
};

export const ReqUserGetUser = (params) => {
    return get("/user/getUser", params).then(res => res);
};

export const ReqUserSaveUser = (data) => {
    return post("/user/saveUser", data).then(res => res);
};

export const ReqUserDelUser = (data) => {
    return request({url: "/user/delUser", method: "post", params: data}).then(res => res);
};

export const ReqUserSetEnabled = (data) => {
    return post("/user/setEnabled", data).then(res => res);
};

export const ReqUserSetLocked = data => {
    return post("/user/setLocked", data).then(res => res);
}

export const reqUserList = (params) => {
    return get("/user/list", params).then(res => res);
};

/**
 * 获取组织下可用用户信息
 * @param data
 * @returns {Promise<unknown>}
 */
export const getUsersByOrgId = data => {
    return get("/user/getUsersByOrgId/" + data).then(res => res);
}
