import {get, post} from "@/service/index.js";

/* 获取当前组织 */
export const getCurrentOrg = () => {
  return get("/sys/user/getCurrentOrg").then((res) => res);
};

/**
 * 获取登录用户
 * @returns {Promise<unknown>}
 */
export const getUser = () => {
  return get("/sys/user/getUser").then((res) => res);
};

/* 切换当前组织 */
export const changeCurrentOrg = (data) => {
  return post("/sys/user/changeCurrentOrg", data).then((res) => res.data);
};
