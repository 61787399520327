import {Random} from "mockjs";
import {res} from "./index";
import {enums} from "./enums";
import {getOrganizations} from "./login";
import {enumValue} from "../src/util/enumUtil";
import {billingTrades} from "./billingInvoice";

Random.extend({
    expenseStatus: function () {
        return this.pick(enumValue(enums.ExpenseBillingStatus))
    },
    organizations: function () {
        return this.pick(getOrganizations())
    },
    collectStatus: function () {
        return this.pick(enumValue(enums.CollectStatus))
    },
    taxRate: function () {
        return this.pick(0.00, 0.01, 0.03, 0.04, 0.05, 0.06, 0.09, 0.10, 0.13, 0.16, 0.17)
    }
})

export let expenseList = [];
for (let i = 0; i < 20; i++) {
    expenseList[i] = {
        "id": i,
        "expenseNo": Random.natural(100000000, 900000000), //费用单号
        "buyerName": Random.organizations().name, // 零售
        "buyerTaxNo": Random.natural(111111110000, 999999999999),
        "sellerName": Random.organizations().name, // 供应
        "taxRate": Random.taxRate(),
        "sellerTaxNo": Random.natural(111111110000, 999999999999),
        "expenseBillingStatus": Random.expenseStatus(), //开票状态
        "collectStatus": Random.collectStatus(), //收款状态
        "sumAmount": Random.float(0, 10000, 0, 3),
        "expenseDate": Random.date("yyyy-MM-dd"), //费用单时间
        "created": new Date(),
        "items": [] //商品明细
    }
}
// 获取费用单列表
const getExpenseList = {
    url: "/cooperate/expense/getList",
    type: "post",
    response: config => {
        // 筛选条件
        const size = config.body.paging.size
        const current = config.body.paging.current;
        let list = expenseList;


        let records = [];
        if (list.length > (current * size)) {
            for (let i = (current - 1) * size; i < current * size; i++) {
                records.push(list[i]);
            }
        } else {
            for (let i = 0; i < list.length; i++) {
                records.push(list[i])
            }
        }

        return res(true, {
            records: records,
            total: list.length,
            size: size,
            current: current
        })
    }
}

// 删除费用单
const deletedExpense = {
    url: "/cooperate/expense/deleted",
    type: "get",
    response: config => {
        const id = config.query.id;
        const index = expenseList.findIndex(i => i.id == id);
        expenseList.splice(index, 1);
        return res(true, true)
    }
}

// 获取费用单详情
const getDetail = {
    url: "/cooperate/expense/detail",
    type: "get",
    response: config => {
        const id = config.query.id;
        const index = expenseList.findIndex(i => i.id == id);
        return res(true, {
            detail: expenseList[index],
            items: [
                {
                    merchandiseCode: "TEST123456",
                    merchandiseName: "测试费用",
                    taxCode: "CO12512SASA",
                    taxRate: expenseList[index].taxRate,
                    sumAmount: expenseList[index].sumAmount
                }
            ]
        })
    }
}

/* 生成待开发票 */
const initBillingTrades = {
    url: "/cooperate/expense/initTrades",
    type: "post",
    response: config => {
        const {billingType, selections} = config.body;
        for (let i = 0; i < selections.length; i++) {
            expenseList[selections[i].id].expenseBillingStatus = "TRADE"

            const billingTrade = {
                "amount": selections[i].sumAmount,
                "auditor": Random.cname(),
                "autoBilling": true,
                "billingFlag": "LP",
                "billingType": billingType,
                "buyerAddressTel": Random.county(true) + " " + Random.natural(16619765837, 19920128294),
                "buyerBankAccount": Random.county(true) + Random.zip(),
                "buyerName": selections[i].buyerName,
                "buyerTaxNo": selections[i].buyerTaxNo,
                "buyerType": Random.buyerType(),
                "created": Random.date('yyyy-MM-dd'),
                "dataFlag": Random.dataFlag(),
                "dataId": selections[i].expenseNo,
                "deleted": Random.boolean(),
                "expressAddress": Random.county(true) + " " + Random.zip(),
                "expressContact": Random.cname(),
                "taxRate": Random.taxRate(),
                "expressPhone": Random.natural(16619765837, 19920128294),
                "id": 35 + i,
                "invoiceTimeRange": [],
                "isWriteBack": Random.boolean(),
                "items": [],
                "orgId": Random.organizations().id,
                "payee": Random.cname(),
                "payer": Random.cname(),
                "receiveEmail": Random.email(),
                "receivePhone": "",
                "receiveRemark": Random.cparagraph(0, 3),
                "sellerAddressTel": Random.county(true) + " " + Random.natural(16619765837, 19920128294),
                "sellerBankAccount": Random.county(true) + " " + Random.natural(7332810182600075904, 73328101826000759041),
                "sellerName": selections[i].sellerName,
                "sellerTaxNo": selections[i].sellerTaxNo,
                "sendMode": Random.sendMode(),
                "source": "SJXX",
                "billingFrom": "EB",
                "sumAmount": selections[i].sumAmount,
                "taxAmount": 10.10,
                "tenantId": 1,
                "updated": new Date(),
                "version": 1,
                "writeBackStatus": null
            };
            billingTrades.unshift(billingTrade)
        }


        return res(true, true)
    }

}

export default [getExpenseList, deletedExpense, getDetail, initBillingTrades];