import {mock, random} from "mockjs";
import {res} from "./index";
import {enums} from "./enums";

/**
 * 账号列表
 * @type {{test: {nickName: string, orgId: number, token: string, username: string}, admin: {nickName: string, orgId: number, token: string, username: string}}}
 */
export let users = [
    {
        nickName: "超管",
        id: 1,
        orgId: 1,
        token: "admin",
        roles: [1],
        password: "123456",
        locked: false,
        manager: true,
        enabled: true,
        orgName: "北京多啦财税科技有限公司",
        username: "admin",
        industry: "RETAIL"
    },
    {
        nickName: "测试人员",
        id: 2,
        orgId: 1,
        roles: [1, 2],
        token: "test",
        password: "123456",
        locked: false,
        manager: false,
        enabled: true,
        orgName: "北京寓乐世界教育科技有限公司",
        username: "test11",
        industry: "HOTEL"
    },
    {
        nickName: "产品",
        id: 3,
        orgId: 1,
        roles: [3],
        token: "test",
        password: "123456",
        locked: false,
        manager: false,
        enabled: true,
        orgName: "北京多啦财税科技有限公司",
        username: "aaaaaa",
        industry: "HOTEL"
    },
    {
        nickName: "禁用人员",
        id: 4,
        orgId: 1,
        roles: [4],
        token: "test",
        password: "123456",
        locked: false,
        manager: false,
        enabled: false,
        orgName: "阿里未来酒店管理（浙江）有限公司",
        username: "bbbbbb",
        industry: "HOTEL"
    },
    {
        nickName: "锁定人员",
        id: 5,
        orgId: 1,
        roles: [4],
        token: "test",
        password: "123456",
        locked: true,
        manager: false,
        enabled: true,
        orgName: "阿里未来酒店管理（浙江）有限公司",
        username: "qqqqqq",
        industry: "HOTEL"
    },
    {
        nickName: "开发",
        id: 6,
        orgId: 1,
        roles: [5],
        token: "test",
        password: "123456",
        locked: false,
        manager: false,
        enabled: true,
        orgName: "北京多啦财税科技有限公司",
        username: "develop",
        industry: "HOTEL"
    },
    {
        nickName: "运营",
        id: 7,
        orgId: 1,
        roles: [3],
        token: "test",
        password: "123456",
        locked: false,
        manager: false,
        enabled: true,
        orgName: "天津五道口分公司",
        username: "manger",
        industry: "HOTEL"
    },

];

const funcRules = ["RH", "RC", "RC-F01", "RC-F02", "RC-F03", "RS", "RS-M01", "RS-M02", "RS-M01-F01", "RS-M01-F02", "RS-M01-F03", "RS-M02-F01",
    "RS-M02-F02", "RS-M02-F03", "RS-M03", "RS-M04", "RS-M03-F01", "RS-M03-F02", "RS-M04-F01", "RS-M04-F02", "RS-M05",
    "RS-M05-F01", "RS-M05-F02", "RS-M06","RB", "RO", "RO-M01", "RO-M02", "RO-M03", "RO-M04", "RO-M05", "RO-M06"];

// 组织列表（树）
const organizations = [
    {
        "addressTel": "北京市昌平区回龙观 000000",
        "address": "北京市昌平区回龙观",
        "bank": "中国建设银行",
        "contacts": "菲住布渴",
        "businessLicense": "营业执照/ee9559e6a66848c9b8693aacc88733b0.jpg",
        "fileUrl": "http://collectticket-files-dev.oss-cn-zhangjiakou.aliyuncs.com/%E8%90%A5%E4%B8%9A%E6%89%A7%E7%85%A7/ee9559e6a66848c9b8693aacc88733b0.jpg?Expires=1922256511&OSSAccessKeyId=LTAI4FiWA1tjaxq3rmPfp7gE&Signature=iAmLJy3Ukq8YDN3WYpQ%2B7vHXPng%3D",
        "account": "00000000",
        "phone": "000000",
        "auditStatus": "Audited",
        "bankAccount": "中国建设银行 00000000",
        "id": 1,
        "children": [
            {
                "addressTel": "昌平区众创空间 00000000",
                "auditStatus": "Audited",
                "bankAccount": "招商银行 181727278238373939",
                "address": "昌平区众创空间",
                "bank": "招商银行",
                "contacts": "菲住布渴",
                "businessLicense": "营业执照/ee9559e6a66848c9b8693aacc88733b0.jpg",
                "fileUrl": "http://collectticket-files-dev.oss-cn-zhangjiakou.aliyuncs.com/%E8%90%A5%E4%B8%9A%E6%89%A7%E7%85%A7/ee9559e6a66848c9b8693aacc88733b0.jpg?Expires=1922256511&OSSAccessKeyId=LTAI4FiWA1tjaxq3rmPfp7gE&Signature=iAmLJy3Ukq8YDN3WYpQ%2B7vHXPng%3D",
                "account": "181727278238373939",
                "phone": "00000000",
                "id": 64,
                "children": [],
                "parentId": 1,
                "parentName": "北京多啦财税科技有限公司",
                "name": "北京多啦水彩科技有限公司",
                "taxNo": "686867879879"
            },
            {
                "addressTel": "测试的 无所谓",
                "auditStatus": "Audited",
                "bankAccount": "招商 895898989894",
                "address": "北京市昌平区回龙观",
                "bank": "中国建设银行",
                "contacts": "菲住布渴",
                "businessLicense": "营业执照/ee9559e6a66848c9b8693aacc88733b0.jpg",
                "fileUrl": "http://collectticket-files-dev.oss-cn-zhangjiakou.aliyuncs.com/%E8%90%A5%E4%B8%9A%E6%89%A7%E7%85%A7/ee9559e6a66848c9b8693aacc88733b0.jpg?Expires=1922256511&OSSAccessKeyId=LTAI4FiWA1tjaxq3rmPfp7gE&Signature=iAmLJy3Ukq8YDN3WYpQ%2B7vHXPng%3D",
                "account": "00000000",
                "phone": "000000",
                "id": 73,
                "children": [],
                "parentId": 1,
                "parentName": "北京多啦财税科技有限公司",
                "name": "北京易道博识科技有限公司",
                "taxNo": "91110108064897136L"
            }
        ],
        "parentId": 0,
        "parentName": "无",
        "name": "北京多啦财税科技有限公司",
        "taxNo": "91110114MA01G5Q98W"
    },
    {
        "addressTel": "杭州市余杭区五常街道乐淘城8号楼 0571-85022088",
        "auditStatus": "Audited",
        "bankAccount": "招商银行股份有限公司杭州高新支行 571909691410801",
        "address": "杭州市余杭区五常街道乐淘城8号楼",
        "bank": "招商银行股份有限公司杭州高新支行",
        "contacts": "菲住布渴",
        "businessLicense": "营业执照/ee9559e6a66848c9b8693aacc88733b0.jpg",
        "fileUrl": "http://collectticket-files-dev.oss-cn-zhangjiakou.aliyuncs.com/%E8%90%A5%E4%B8%9A%E6%89%A7%E7%85%A7/ee9559e6a66848c9b8693aacc88733b0.jpg?Expires=1922256511&OSSAccessKeyId=LTAI4FiWA1tjaxq3rmPfp7gE&Signature=iAmLJy3Ukq8YDN3WYpQ%2B7vHXPng%3D",
        "account": "571909691410801",
        "phone": "0571-85022088",
        "id": 2,
        "parentName": "无",
        "children": [
            {
                "addressTel": "北京 18978111111",
                "auditStatus": "Audited",
                "bankAccount": " ",
                "address": "北京市昌平区回龙观",
                "bank": "中国建设银行",
                "contacts": "菲住布渴",
                "businessLicense": "营业执照/ee9559e6a66848c9b8693aacc88733b0.jpg",
                "fileUrl": "http://collectticket-files-dev.oss-cn-zhangjiakou.aliyuncs.com/%E8%90%A5%E4%B8%9A%E6%89%A7%E7%85%A7/ee9559e6a66848c9b8693aacc88733b0.jpg?Expires=1922256511&OSSAccessKeyId=LTAI4FiWA1tjaxq3rmPfp7gE&Signature=iAmLJy3Ukq8YDN3WYpQ%2B7vHXPng%3D",
                "account": "00000000",
                "phone": "18978111111",
                "id": 5,
                "children": [],
                "parentId": 2,
                "parentName": "阿里未来酒店管理（浙江）有限公司",
                "name": "天津五道口分公司",
                "taxNo": "1234567890"
            },
            {
                "addressTel": "深圳 17811111111",
                "auditStatus": "Pending",
                "bankAccount": "中国建设银行 00000000",
                "address": "深圳",
                "bank": "中国建设银行",
                "contacts": "菲住布渴",
                "businessLicense": "营业执照/ee9559e6a66848c9b8693aacc88733b0.jpg",
                "fileUrl": "http://collectticket-files-dev.oss-cn-zhangjiakou.aliyuncs.com/%E8%90%A5%E4%B8%9A%E6%89%A7%E7%85%A7/ee9559e6a66848c9b8693aacc88733b0.jpg?Expires=1922256511&OSSAccessKeyId=LTAI4FiWA1tjaxq3rmPfp7gE&Signature=iAmLJy3Ukq8YDN3WYpQ%2B7vHXPng%3D",
                "account": "00000000",
                "phone": "000000",
                "id": 9,
                "children": [],
                "parentId": 2,
                "parentName": "阿里未来酒店管理（浙江）有限公司",
                "name": "多啦北京昌平回龙观分公司",
                "taxNo": "112312332456"
            }
        ],
        "parentId": 0,
        "name": "阿里未来酒店管理（浙江）有限公司",
        "taxNo": "91330110MA27XY00XD"
    },
    {
        "addressTel": "北京市海淀区海淀中街15号楼3 82183719",
        "auditStatus": "Audited",
        "bankAccount": "中国建设银行, 12412551312 ",
        "address": "北京市海淀区海淀中街15号楼3",
        "bank": "中国建设银行",
        "contacts": "测试",
        "businessLicense": "营业执照/ee9559e6a66848c9b8693aacc88733b0.jpg",
        "fileUrl": "http://collectticket-files-dev.oss-cn-zhangjiakou.aliyuncs.com/%E8%90%A5%E4%B8%9A%E6%89%A7%E7%85%A7/ee9559e6a66848c9b8693aacc88733b0.jpg?Expires=1922256511&OSSAccessKeyId=LTAI4FiWA1tjaxq3rmPfp7gE&Signature=iAmLJy3Ukq8YDN3WYpQ%2B7vHXPng%3D",
        "account": "12412551312",
        "phone": "82183719",
        "id": 26,
        "children": [],
        "parentId": 0,
        "parentName": "无",
        "name": "北京寓乐世界教育科技有限公司",
        "taxNo": "911101070536344881"
    },
    {
        "addressTel": "北京 18436767899",
        "auditStatus": "Audited",
        "bankAccount": "招商银行 123123",
        "address": "北京市昌平区回龙观",
        "bank": "中国建设银行",
        "contacts": "菲住布渴",
        "businessLicense": "营业执照/ee9559e6a66848c9b8693aacc88733b0.jpg",
        "fileUrl": "http://collectticket-files-dev.oss-cn-zhangjiakou.aliyuncs.com/%E8%90%A5%E4%B8%9A%E6%89%A7%E7%85%A7/ee9559e6a66848c9b8693aacc88733b0.jpg?Expires=1922256511&OSSAccessKeyId=LTAI4FiWA1tjaxq3rmPfp7gE&Signature=iAmLJy3Ukq8YDN3WYpQ%2B7vHXPng%3D",
        "account": "00000000",
        "phone": "000000",
        "id": 51,
        "children": [],
        "parentId": 0,
        "parentName": "无",
        "name": "iUKY测试组织",
        "taxNo": "123456788765432111"
    },
    {
        "addressTel": "杭州市西湖区西溪路550号西溪新座1幢 0571-87680608",
        "auditStatus": "Audited",
        "bankAccount": "中信银行杭州湖墅支行 7332810182600075904",
        "address": "杭州市西湖区西溪路550号西溪新座1幢",
        "bank": "中信银行杭州湖墅支行",
        "contacts": "菲住布渴",
        "businessLicense": "营业执照/ee9559e6a66848c9b8693aacc88733b0.jpg",
        "fileUrl": "http://collectticket-files-dev.oss-cn-zhangjiakou.aliyuncs.com/%E8%90%A5%E4%B8%9A%E6%89%A7%E7%85%A7/ee9559e6a66848c9b8693aacc88733b0.jpg?Expires=1922256511&OSSAccessKeyId=LTAI4FiWA1tjaxq3rmPfp7gE&Signature=iAmLJy3Ukq8YDN3WYpQ%2B7vHXPng%3D",
        "account": "7332810182600075904",
        "phone": "0571-87680608",
        "id": 54,
        "children": [],
        "parentId": 0,
        "parentName": "无",
        "name": "杭州心隅酒店管理有限公司",
        "taxNo": "9133010632287785XC"
    }
]

export const getOrganizations = T => {
    if (T == "tree") {
        return organizations;
    } else {
        let orgs = [];
        toOrgList(organizations, orgs);
        return orgs;
    }
}

const toOrgList = (orgs = [], l = []) => {
    orgs.forEach(o => {
        l.push(o);
        if (o.children.length > 0) {
            toOrgList(o.children, l)
        }
    })
}

// 登录
const login = {
    url: "/login",
    type: "post",
    response: config => {
        const {username, password} = config.body;
        let user;
        users.map(i => {
            if (i.username == username) user = i;
        });
        if (!user) {
            res(false, [], "账号不存在")
        }
        return user.password != password ? res(false, [], "账号密码错误") : res(true, user);
    }
}
// 权限列表
const getFuncRoles = {
    url: "/user/getFuncScope",
    type: "get",
    response: config => res(true, funcRules)
}
// 获取枚举类
const getEnums = {
    url: "/enums/list",
    type: "get",
    response: config => res(true, enums)
}
// 获取组织列表
const getOrganizationList = {
    url: "/organization/getSelectList",
    type: "get",
    response: config => res(true, getOrganizations)
}

export default [login, getFuncRoles, getEnums, getOrganizationList];
