export default {
    namespaced: true,
    state: {
        copyInvoice: {},
        CurrentOrganization: ''
    },
    mutations: {
        // 复制已开发票
        setCopyInvoice(state, val) {
            state.copyInvoice = val;
        },
        clearCopyInvoice(state){
            state.copyInvoice = {};
        },
        /** 设置租户的组织列表 */
        SET_CURRENT_ORGANIZATION(state, organization) {
            state.CurrentOrganization = organization;
        }
    }
}
