<template>
    <div class="label-span">
        <label>{{label}}</label>
        <span>{{span}}</span>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "LabelSpan",
        props: ['label', 'span']
    }
</script>

<style lang="scss" scoped>
    .label-span {
        label {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 20px;
            min-width: 100px;
        }

        span {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
        }
    }
</style>
