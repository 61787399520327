import Mock from 'mockjs';
import {paramObj} from "./util";
import login from "./login";
import common from "./common";
import billingInvoice from "./billingInvoice";
import invoiceCenter from "./invoiceCenter";
import userManage from "./userManage";
import roleManage from "./roleManage";
import equipment from "./equipment";
import merchandise from "./merchandise";
import expense from "./expense";
import sellerManage from "./sellerManage";
import email from "./email";
import settlement from "./settlement";

/* 返回模版 */
export const res = (success, data, message) => {
    if (success) {
        return {
            code: 1,
            success: success,
            data: data,
            message: message || "处理成功"
        }
    } else {
        return {
            code: 0,
            success: success,
            data: [],
            messages: message || "处理失败"
        }
    }
}
/* mock接口列表 */
export const mocks = [...login, ...common, ...billingInvoice, ...invoiceCenter,
    ...userManage, ...roleManage, ...equipment, ...merchandise, ...settlement, ...expense, ...sellerManage, ...email];

export const mockXHR = () => {
    Mock.setup({timeout: '100-300'});
    Mock.XHR.prototype.proxy_send = Mock.XHR.prototype.send
    Mock.XHR.prototype.send = function () {
        if (this.custom.xhr) {
            this.custom.xhr.withCredentials = this.withCredentials || false

            if (this.responseType) {
                this.custom.xhr.responseType = this.responseType
            }
        }
        this.proxy_send(...arguments)
    }

    function XHRExpressReqWrap(respond) {
        return function (options) {
            let result = null
            if (respond instanceof Function) {
                const {body, type, url} = options
                result = respond({
                    url: url,
                    method: type,
                    body:  body instanceof FormData ? JSON.parse(JSON.stringify(body)) :JSON.parse(body),
                    query: paramObj(url)
                })
            } else {
                result = respond
            }
            return Mock.mock(result)
        }
    }

    for (const i of mocks) {
        Mock.mock(new RegExp(i.url), i.type || 'get', XHRExpressReqWrap(i.response))
    }
}
