import {mock, Random} from "mockjs";
import {res} from "./index";
import {enums} from "./enums";
import {getOrganizations} from "./login";
import {enumValue} from "../src/util/enumUtil";
import {equipments} from "./equipment";
import {expenseList} from "./expense";

Random.extend({
    billingFlag: function () {
        return this.pick(["LP", "HP"])
    },
    billingType: function () {
        return this.pick(enumValue(enums.BillingType))
    },
    buyerType: function () {
        return this.pick(["Gr", "Qy"])
    },
    dataFlag: function () {
        return this.pick(enumValue(enums.DataFlag))
    },
    sendMode: function () {
        return this.pick(enumValue(enums.BillingSendMode))
    },
    organizations: function () {
        return this.pick(getOrganizations())
    },
    invoiceStatus: function () {
        return this.pick(enumValue(enums.InvoiceStatus))
    },
    printingStatus: function () {
        return this.pick(enumValue(enums.PrintingStatus))
    },
    taxRate: function () {
        return this.pick(0.00, 0.01, 0.03, 0.04, 0.05, 0.06, 0.09, 0.10, 0.13, 0.16, 0.17)
    }
})

const count = 35;
export let billingTrades = [];     // 待开发票列表
let billingInvoices = [];   // 已开发票列表
// let equipments = [];        // 开票设备列表
let invoiceCode = "";
let invoiceNo = "";


for (let i = 0; i < count; i++) {
    const amount = Random.float(0, 10000, 0, 3);
    const taxRate = Random.taxRate();
    const taxAmount = taxRate * amount;
    const sumAmount = taxAmount + amount;
    billingTrades[i] = {
        "amount": amount,
        "auditor": Random.cname(),
        "autoBilling": Random.boolean(),
        "billingFlag": Random.billingFlag(),
        "billingType": Random.billingType(),
        "buyerAddressTel": Random.county(true) + " " + Random.natural(16619765837, 19920128294),
        "buyerBankAccount": Random.county(true) + Random.zip(),
        "buyerName": Random.organizations().name,
        "buyerTaxNo": Random.natural(111111110000, 999999999999),
        "buyerType": Random.buyerType(),
        "created": Random.date('yyyy-MM-dd'),
        "dataFlag": Random.dataFlag(),
        "dataId": Random.natural(111111110000, 999999999999),
        "deleted": Random.boolean(),
        "expressAddress": Random.county(true) + " " + Random.zip(),
        "expressContact": Random.cname(),
        "taxRate": taxRate,
        "expressPhone": Random.natural(16619765837, 19920128294),
        "id": i,
        "invoiceTimeRange": [],
        "isWriteBack": Random.boolean(),
        "items": [],
        "orgId": Random.organizations().id,
        "payee": Random.cname(),
        "payer": Random.cname(),
        "receiveEmail": Random.email(),
        "receivePhone": "",
        "receiveRemark": Random.cparagraph(0, 3),
        "roomId": Random.natural(100, 500),
        "sellerAddressTel": Random.county(true) + " " + Random.natural(16619765837, 19920128294),
        "sellerBankAccount": Random.county(true) + " " + Random.natural(7332810182600075904, 73328101826000759041),
        "sellerName": Random.organizations().name,
        "sellerTaxNo": Random.string(15, 15),
        "sendMode": Random.sendMode(),
        "source": "FZBK",
        "billingFrom": "EB",
        "sumAmount": sumAmount,
        "taxAmount": taxAmount,
        "tenantId": 1,
        "updated": Random.date('yyyy-MM-dd'),
        "version": 1,
        "writeBackStatus": null
    }

    for (let j = 0; j < 3; j++) {
        let item = {
            "amount": amount,
            "billingTradeId": i,
            "created": new Date(),
            "dataId": "1603746034781021089810040",
            "deleted": false,
            "id": j,
            "merchandise": null,
            "merchandiseName": "测试商品明细",
            "orderDate": "2020-10-27 00:00:00",
            "price": 0.05,
            "quantity": 1.00,
            "source": "FlyZoo",
            "specification": "型号",
            "sumAmount": sumAmount,
            "taxAmount": taxAmount,
            "taxClassCode": "3070402000000000000",
            "taxClassName": "住宿服务",
            "taxRate": taxRate,
            "taxVersionNo": "",
            "tenantId": 1,
            "unTaxPrice": 0.05,
            "uniqueCode": "1000",
            "unit": "个",
            "updated": "2020-11-05 18:43:21",
            "version": 1
        }
        billingTrades[i].items.push(item);
    }

}

// for (let i = 0; i < getOrganizations().length; i++) {
//     equipments[i] = {
//         agentPassword: Random.natural(1234567890, 9876543210),
//         agentUsername: "91110114MA01G5Q98W-B",
//         auditor: Random.cname(),
//         electronicOrdinaryInvoiceLimit: 1000,
//         electronicSpecialInvoiceLimit: 0,
//         extensionNo: "B",
//         id: getOrganizations()[i].id,
//         manufacturer: "HX",
//         paperOrdinaryInvoiceLimit: 1000,
//         paperSpecialInvoiceLimit: 1000,
//         password: "66666666",
//         payee: Random.cname(),
//         payer: Random.cname()
//     }
// }

// 获取待开列表
const getBillingTrades = {
    url: "/billing/trade/waitList",
    type: "post",
    response: config => {
        const size = config.body.paging.size
        const current = config.body.paging.current;

        let list = billingTrades;
        if (config.body.filter.dataId) {
            list = list.filter(i => i.dataId == config.body.filter.dataId);
        }
        if (config.body.filter.billingTyp) {
            list = list.filter(i => i.billingType == config.body.filter.billingType);
        }
        if (config.body.filter.buyerName) {
            list = list.filter(i => new RegExp(config.body.filter.buyerName).test(i.buyerName));
        }

        let records = [];
        for (let i = (current - 1) * size; i < current * size; i++) {
            records.push(list[i]);
        }
        return res(true, {
            records: records,
            total: list.length,
            size: size,
            current: current
        })
    }
}

// 获取明细
const getBillingTradeById = {
    url: "/billing/trade/selectInvoice",
    type: "get",
    response: config => {
        const id = config.url.split("/")[config.url.split("/").length - 1];
        const trade = billingTrades.find(i => i.id == id);
        return res(true, trade)
    }
}

// 合并明细
const mergeDetails = {
    url: "billing/trade/items/mergeInvoiceItem",
    type: "post",
    response: config => {
        const filter = config.body;
        const index = billingTrades.findIndex(i => i.id == filter[0].billingTradeId)
        billingTrades[index].items.length = filter.length > 2 ? 1 : 2;
        return res(true, filter[0].billingTradeId)
    }
}

// 拆分单据
const tradeSplitBill = {
    url: "/billing/trade/items/mergeInvoice",
    type: "post",
    response: config => res(true, true)
}

// 获取库存
const getInventory = {
    url: "/invoice/billing/getInventory",
    type: "post",
    response: config => {
        invoiceCode = Random.natural(111111110000, 999999999999);
        invoiceNo = Random.natural(111111110000, 999999999999);
        return res(true, {
            agentUsername: "9133010632287785XC-0",
            invoiceCode: invoiceCode,
            invoiceNo: invoiceNo,
            residueQuantity: 100
        })
    }
}

// 发票开具并打印
const invoiceBilling = {
    url: "/billing/trade/invoiceBilling",
    type: "get",
    response: config => {
        const id = config.url.split("/")[config.url.split("/").length - 1];
        const index = billingTrades.findIndex(i => i.id == id);

        // 补全已开发票信息
        billingTrades[index].invoiceNo = invoiceNo;
        billingTrades[index].invoiceCode = invoiceCode;
        billingTrades[index].invoiceTime = new Date();
        billingTrades[index].invoiceStatus = Random.invoiceStatus();
        billingInvoices.push(billingTrades[index]);
        if (billingTrades[index].source == "SJXX") {
            const ins = expenseList.findIndex(i => i.expenseNo == billingTrades[index].dataId);
            expenseList[ins].expenseBillingStatus = "ALREADY"
        }
        billingTrades.splice(index, 1);
        return res(true, true)
    }
}

// 获取已开发票列表
const getBillingInvoices = {
    url: "/invoice/billing/getInvoiceAlreadyListByPage",
    type: "post",
    response: config => {
        // 筛选条件
        const size = config.body.paging.size
        const current = config.body.paging.current;
        let list = billingInvoices;
        if (config.body.filter.dataId) {
            list = list.filter(i => new RegExp(config.body.filter.dataId).test(i.dataId));
        }
        if (config.body.filter.invoiceTimeRange) {
            list = list.filter(i => {
                return (i.invoiceTime.getTime() <= config.body.filter.invoiceTimeRange[1] &&
                    config.body.filter.invoiceTimeRange[0] <= i.invoiceTime.getTime())
            })
        }
        if (config.body.filter.invoiceCodeNo) {
            list = list.filter(i => {
                return (new RegExp(config.body.filter.invoiceCodeNo).test(i.invoiceCode) ||
                    new RegExp(config.body.filter.invoiceCodeNo).test(i.invoiceNo))
            });
        }
        if (config.body.filter.billingTyp) {
            list = list.filter(i => i.billingType == config.body.filter.billingType);
        }
        if (config.body.filter.invoiceStatus) {
            list = list.filter(i => i.invoiceStatus == config.body.filter.invoiceStatus);
        }
        if (config.body.filter.buyerName) {
            list = list.filter(i => new RegExp(config.body.filter.buyerName).test(i.buyerName));
        }


        let records = [];
        if (list.length > (current * size)) {
            for (let i = (current - 1) * size; i < current * size; i++) {
                records.push(list[i]);
            }
        } else {
            for (let i = 0; i < list.length; i++) {
                records.push(list[i])
            }
        }

        return res(true, {
            records: records,
            total: list.length,
            size: size,
            current: current
        })
    }
}

// 已开发票详情
const selectInvoice = {
    url: "/invoice/billing/selectInvoice/",
    type: "get",
    response: config => {
        const id = config.url.split("/")[config.url.split("/").length - 1];
        const index = billingInvoices.findIndex(i => i.id == id);
        billingInvoices[index].items = [{
            "amount": billingInvoices[index].amount,
            "billingInvoiceId": Random.natural(100, 10000),
            "created": billingInvoices[index].invoiceTime,
            "dataId": "1602626582239021360410004-0",
            "deleted": false,
            "id": id,
            "merchandise": null,
            "merchandiseCode": "",
            "merchandiseName": Random.ctitle(1, 5),
            "orderDate": "2020-10-14 00:00:00",
            "price": billingInvoices[index].sumAmount,
            "quantity": 1.00,
            "source": "FZBK",
            "specification": "",
            "sumAmount": billingInvoices[index].sumAmount,
            "taxAmount": billingInvoices[index].taxAmount,
            "taxClassCode": "3070402000000000000",
            "taxClassName": "住宿服务",
            "taxRate": billingInvoices[index].taxRate,
            "taxVersionNo": "",
            "tenantId": 1,
            "unTaxPrice": billingInvoices[index].amount,
            "uniqueCode": "1000",
            "unit": "",
            "updated": new Date(),
            "version": 1
        }];
        return res(true, billingInvoices[index]);
    }
}

// 根据开票组织获取销方
const getEquipmentByOrgId = {
    url: "/invoice/manual/getEquipmentByOrgId",
    type: "get",
    response: config => res(true, equipments[config.query.orgId])
}

// 获取商品列表
const getMerchandiseList = {
    url: "/merchandise/getMerchandiseGroup",
    type: "post",
    response: config => {
        let list = [];
        for (let i = 0; i < 50; i++) {
            list[i] = {
                "created": new Date(),
                "deleted": false,
                "id": i,
                "merchandiseName": Random.ctitle(5, 10),
                "orgId": 1,
                "price": Random.float(0, 10000, 0, 3),
                "specification": "00000001",
                "taxClassCode": Random.natural(100, 1000),
                "taxClassName": Random.ctitle(5, 10),
                "taxRate": Random.taxRate(),
                "taxSign": 1,
                "taxType": Random.taxRate(),
                "taxVersionNo": "1",
                "tenantId": 1,
                "uniqueCode": i,
                "unit": 1,
                "updated": new Date(),
                "version": 1
            }
        }
        return res(true, list)
    }
}

// 抬头模糊搜索
const getBuyerName = {
    url: "/invoice/manual/getBuyerName",
    type: "get",
    response: config => res(true, [
        {
            "addressPhone": "北京市昌平区回龙观 000000",
            "bankAccount": "中国建设银行 00000000",
            "usingCount": 20,
            "star": true,
            "name": "北京多啦财税科技有限公司",
            "taxCode": "91110114MA01G5Q98W"
        },
        {
            "addressPhone": "杭州市余杭区五常街道乐淘城8号楼 0571-85022088",
            "bankAccount": "招商银行股份有限公司杭州高新支行 571909691410801",
            "usingCount": 2,
            "star": false,
            "name": "阿里未来酒店管理（浙江）有限公司",
            "taxCode": "91330110MA27XY00XD"
        },
        {
            "addressPhone": "北京市海淀区海淀中街15号楼3 82183719",
            "bankAccount": "招商银行股份有限公司杭州高新支行 571909691410801",
            "star": false,
            "usingCount": 26,
            "name": "北京寓乐世界教育科技有限公司",
            "taxCode": "911101070536344881"
        },
        {
            "addressPhone": "杭州市西湖区西溪路550号西溪新座1幢 0571-87680608",
            "bankAccount": "中信银行杭州湖墅支行 7332810182600075904",
            "star": false,
            "usingCount": 54,
            "name": "杭州心隅酒店管理有限公司",
            "taxCode": "9133010632287785XC"
        },
    ])
}

// 手工开票
const billingManualInvoice = {
    url: "/invoice/manual/billingManualInvoice",
    type: "post",
    response: config => {
        const manualInvoice = config.body;
        manualInvoice.invoiceTime = new Date();
        manualInvoice.invoiceStatus = Random.invoiceStatus();
        manualInvoice.source = "FZBK";
        manualInvoice.dataFlag = Random.dataFlag();
        manualInvoice.sendMode = Random.sendMode();
        manualInvoice.invoicePrintStatus = Random.printingStatus();
        billingInvoices.unshift(manualInvoice);
        return res(true, true)
    }
}

export default [getBillingTrades, getInventory,
    invoiceBilling, getBillingInvoices,
    selectInvoice, getEquipmentByOrgId, getMerchandiseList,
    getBuyerName, billingManualInvoice, getBillingTradeById, mergeDetails, tradeSplitBill]
