export default {
    namespaced: true,
    state: {
        /** 获取存储租户的组织列表 */
        get organizations() {
            return JSON.parse(localStorage.getItem('organizations'));
        },
        /** 设置存储租户的组织列表 */
        set organizations(value) {
            localStorage.setItem('organizations', JSON.stringify(value));
        },
        get industry() {
            return localStorage.getItem('INDUSTRY');
        },
        set industry(value) {
            localStorage.setItem('INDUSTRY', value);
        },
        get currUser() {
            return JSON.parse(localStorage.getItem('CURR_USER'));
        },
        set currUser(value) {
            localStorage.setItem('CURR_USER', JSON.stringify(value));
        }
    },
    getters: {},
    mutations: {
        /** 设置租户的组织列表 */
        SET_ORGANIZATIONS(state, organizations) {
            state.organizations = organizations;
        },
        /** 清除租户的组织列表 */
        CLEAR_ORGANIZATIONS(state) {
            state.organizations = [];
        },
        SET_INDUSTRY(state, val) {
            state.industry = val;
        },
        SET_CURR_USER(state, val) {
            state.currUser;
        }
    },
    actions: {}
}
